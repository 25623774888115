import { SnackbarOrigin } from '@mui/material/Snackbar/Snackbar'
import { type RecoilState, atom } from 'recoil'

export interface INotification {
  id: string
  type: 'warning' | 'success' | 'error'
  message?: string
  autoHideDuration?: number
  anchor?: SnackbarOrigin
}

export const notificationAtom: RecoilState<INotification[]> = atom<
  INotification[]
>({
  key: 'notificationState',
  default: [],
})
