import { Resource } from 'i18next'
import enZodTranslation from 'zod-i18n-map/locales/en/zod.json'
import ptZodTranslation from 'zod-i18n-map/locales/pt/zod.json'

import { LanguageEnum } from '@/shared/enums'

import { en } from './en'
import { pt } from './pt'

const availableTranslations: Resource = {
  [LanguageEnum.EN]: {
    ...en,
    zod: enZodTranslation,
  },
  [LanguageEnum.PT]: {
    ...pt,
    zod: ptZodTranslation,
  },
}

export default availableTranslations
