import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

export interface FullScreenSpinnerProps {
  open?: boolean
  handleClose?: () => void
}

export function FullScreenSpinner({
  open = true,
  handleClose = () => {},
}: FullScreenSpinnerProps) {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      onClick={handleClose}
    >
      <CircularProgress color="info" />
    </Backdrop>
  )
}
