import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { apiClient } from '@/api/client'
import { AppStorage } from '@/shared/utils/app-storage'

const TenantSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
})

export type TenantType = z.infer<typeof TenantSchema>

export const getMyTenants = async () => {
  const response = await apiClient.get('/tenants/mine')
  return z.array(TenantSchema).parse(response.data)
}

export const useMyTenants = () =>
  useQuery({
    queryKey: ['tenants', 'owned'],
    queryFn: getMyTenants,
    initialData: [],
  })

const tenantStorage = AppStorage.load<TenantType>('tenant', TenantSchema)

export const getLocalTenant = () => {
  return tenantStorage.get()
}
