import { ReactElement, Suspense, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { RouterProvider } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'

import { PrimeAuth } from '@primeit/react-auth'
import { ErrorBoundary, darkTheme, lightTheme } from '@primeit/react-core'
import { AuthLoader, ErrorPage, UnauthorizedPage } from '@primeit/react-pages'

import { CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material'
import { LicenseInfo } from '@mui/x-license'
import { QueryClientProvider } from '@tanstack/react-query'
import { RecoilRoot, useRecoilValue } from 'recoil'

import { darkModeAtom } from '@/atoms/dark-mode.atom'
import { FullScreenSpinner } from '@/components/FullScreenSpinner'
import { NotificationsContext } from '@/components/NotificationsContext'
import { queryClient } from '@/query-client'
import { router } from '@/routes'

import './i18n'

LicenseInfo.setLicenseKey(
  '05cee51a740b2f78636f4d0e8084fedaTz05NzUxNyxFPTE3NTcwNzI0ODMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg=='
)

const Loader = () => {
  return <AuthLoader />
}

const Unauthorized = () => {
  return <UnauthorizedPage />
}

const ThemedApp = () => {
  const isSystemDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const isUserDefinedDarkMode = useRecoilValue(darkModeAtom)

  const { i18n } = useTranslation()

  const isDarkModeEnabled = useMemo(
    () =>
      isUserDefinedDarkMode !== null ? isUserDefinedDarkMode : isSystemDarkMode,
    [isUserDefinedDarkMode, isSystemDarkMode]
  )

  const theme = useMemo(
    () => (isDarkModeEnabled ? darkTheme : lightTheme),
    [isDarkModeEnabled]
  )

  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<FullScreenSpinner />}>
        <CssBaseline />
        <ErrorBoundary
          fallback={<ErrorPage message={'Algo aconteceu por aqui!'} />}
        >
          <PrimeAuth
            ssoUrl={import.meta.env.VITE_SSO_URL}
            clientId={import.meta.env.VITE_SSO_CLIENT_ID}
            audience={import.meta.env.VITE_SSO_AUDIENCE}
            redirectUri={window.location.origin}
            onSignIn={(data) => {
              if (data?.profile)
                void i18n.changeLanguage(String(data.profile.language))
            }}
            unauthorizedPage={Unauthorized}
            loadingPage={Loader}
          >
            <QueryClientProvider client={queryClient}>
              <RouterProvider router={router} />
              <NotificationsContext />
            </QueryClientProvider>
          </PrimeAuth>
        </ErrorBoundary>
      </Suspense>
    </ThemeProvider>
  )
}

export default function App(): ReactElement {
  return (
    <RecoilRoot>
      <ErrorBoundary
        fallback={<ErrorPage message={'Algo aconteceu por aqui!'} />}
      >
        <ThemedApp />
      </ErrorBoundary>
    </RecoilRoot>
  )
}
