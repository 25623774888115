import { type AtomEffect } from 'recoil'

import { LocalStorageEnum } from '@/shared/enums'
import { AppStorage } from '@/shared/utils/app-storage'

export const localStorageEffect =
  <T>(key: LocalStorageEnum): AtomEffect<T> =>
  ({ setSelf, onSet }) => {
    const appStorage = AppStorage.load<T>(key)
    const savedValue = appStorage.get()
    if (savedValue != null) {
      setSelf(savedValue)
    }

    onSet((newValue, _, isReset) => {
      isReset || newValue === null
        ? appStorage.remove()
        : appStorage.set(newValue)
    })
  }
