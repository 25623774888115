import { useCallback, useEffect } from 'react'

import { Close } from '@mui/icons-material'
import { Alert, IconButton, Snackbar } from '@mui/material'

import { INotification } from '@/atoms/notifications'
import { useNotification } from '@/hooks/useNotification'

export function NotificationItem({
  id,
  type,
  autoHideDuration,
  message,
  anchor = {
    vertical: 'bottom',
    horizontal: 'left',
  },
}: INotification) {
  const { dismissNotification } = useNotification()

  const handleClose = useCallback((): void => {
    dismissNotification(id)
  }, [dismissNotification, id])

  useEffect(() => {
    const timer = setTimeout(handleClose, autoHideDuration)
    return () => {
      clearTimeout(timer)
    }
  }, [handleClose, autoHideDuration])

  const executeAction = (): void => {
    // action?.();
    dismissNotification(id)
  }

  return (
    <Snackbar anchorOrigin={anchor} open={true} onClose={handleClose}>
      <Alert
        severity={type}
        style={
          {
            // cursor: action ? 'pointer' : 'default',
          }
        }
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleClose}
          >
            <Close fontSize="inherit" />
          </IconButton>
        }
        onClick={executeAction}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}
