import { useRecoilValue } from 'recoil'

import { notificationAtom } from '@/atoms/notifications'
import { NotificationItem } from '@/components/NotificationsContext/NotificationItem'

export function NotificationsContext() {
  const notifications = useRecoilValue(notificationAtom)

  return (
    <>
      {notifications.map((notification) => (
        <NotificationItem key={notification.id} {...notification} />
      ))}
    </>
  )
}
