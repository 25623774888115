import { z } from 'zod'

export const newGoalsFormSchema = z.object({
  invoicedLisbon: z.number(),
  invoicedPorto: z.number(),
})

export type NewGoalsFormInputs = z.infer<typeof newGoalsFormSchema>

export interface GoalResponse {
  id: number
  invoicedLisbon: number
  differenceLisbon: number
  invoicedPorto: number
  differencePorto: number
  invoicedTotal: number
  differenceTotal: number
  createdAt: string
  isActive: boolean
}

export const EmployeeByDepartmentSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  rolesCount: z.number(),
  employeesCount: z.number(),
  roles: z
    .object({
      id: z.string().uuid(),
      name: z.string(),
      employeesCount: z.number(),
    })
    .array(),
})

export const EmployeesByDepartmentSchema = EmployeeByDepartmentSchema.array()

export type EmployeesByDepartment = z.infer<typeof EmployeesByDepartmentSchema>

const CardItem = z.object({
  id: z.string().uuid(),
  label: z.string(),
})

export const CardListResponse = z.array(CardItem)

export enum CardSize {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

const ListItemSchema = z.record(z.string(), z.string().nullable())

const BlockDataSchema = z.discriminatedUnion('type', [
  z.object({ type: z.literal('report_item'), data: z.number() }),
  z.object({
    type: z.literal('report_list'),
    data: z.array(ListItemSchema),
  }),
  z.object({
    type: z.literal('goal'),
    data: z.object({
      active: z.boolean(),
      startDate: z.string().datetime(),
      endDate: z.string().datetime(),
      name: z.string(),
      metrics: z.array(
        z.object({
          name: z.string(),
          outLabel: z.string().nullish(),
          currentValue: z.number(),
          expectedValue: z.number(),
          difference: z.number(),
          percentage: z.number(),
        })
      ),
    }),
  }),
])

export enum CardColor {
  LIGHT_GREY = 'light_grey',
  DARK_GREY = 'dark_grey',
  LIGHT_BLUE = 'light_blue',
  DARK_BLUE = 'dark_blue',
  LIGHT_RED = 'light_red',
  LIGHT_GREEN = 'light_green',
}

export const BlockSchema = BlockDataSchema.and(
  z.object({
    id: z.string(),
    label: z.string(),
    position: z.number(),
    color: z.nativeEnum(CardColor),
    customColor: z.boolean().optional(),
  })
)

export type BlockType = z.infer<typeof BlockSchema>

export const CardSchema = z
  .discriminatedUnion('type', [
    z.object({ type: z.literal('content'), blocks: z.array(BlockSchema) }),
    z.object({
      type: z.literal('info'),
      data: z.number(),
    }),
  ])
  .and(
    z.object({
      size: z.nativeEnum(CardSize),
      showLabel: z.boolean(),
    })
  )

export const SimpleDashboard = z.object({
  url: z.string(),
  name: z.string(),
})

export const DashboardsResponse = z.array(SimpleDashboard)

export const DashboardSchema = SimpleDashboard

export type DashboardType = z.infer<typeof DashboardSchema>

const SimpleGoal = z.object({
  id: z.string().uuid(),
  name: z.string(),
})

export type SimpleGoalType = z.infer<typeof SimpleGoal>

export const GoalsResponse = z.array(SimpleGoal)

export const GoalSchema = z.object({
  name: z.string(),
  active: z.boolean(),
  startDate: z.string().datetime(),
  endDate: z.string().datetime(),
  metrics: z.array(
    z.object({
      name: z.string(),
      currentValue: z.number(),
      expectedValue: z.number(),
      difference: z.number(),
      percentage: z.number(),
    })
  ),
})

export type GoalType = z.infer<typeof GoalSchema>
