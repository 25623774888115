import { atom, useRecoilState, useRecoilValue } from 'recoil'

import { TenantType } from '@/api/tenants/service'
import { localStorageEffect } from '@/atoms/localStorageEffect'
import { LocalStorageEnum } from '@/shared/enums'

export const tenantAtom = atom<TenantType | null>({
  key: 'system/tenant',
  default: null,
  effects: [localStorageEffect(LocalStorageEnum.tenant)],
})

export const useCurrentTenant = () => {
  return useRecoilValue(tenantAtom)
}

export const useTenantContext = () => useRecoilState(tenantAtom)
