import { ZodSchema } from 'zod'

export class AppStorage<T = string> {
  private readonly name: string
  private readonly parser?: ZodSchema

  constructor(key: string, parser?: ZodSchema<T>) {
    this.name = `${import.meta.env.PROD ? 'production' : 'dev'}_${key}`
    this.parser = parser
  }

  get(): T | null {
    const raw = localStorage.getItem(this.name)
    if (raw === null) return null
    const json = JSON.parse(raw) as T
    if (this.parser) return this.parser.parse(json) as T
    return json
  }

  set(data: T) {
    if (this.parser) {
      this.parser.parse(data)
    }
    localStorage.setItem(this.name, JSON.stringify(data))
  }

  remove() {
    localStorage.removeItem(this.name)
  }

  public static load<T = string>(key: string, validator?: ZodSchema<T>) {
    return new AppStorage<T>(key, validator)
  }

  public static getItem<T>(key: string): T | null {
    return AppStorage.load<T>(key).get()
  }

  public static setItem<T>(key: string, data: T) {
    AppStorage.load<T>(key).set(data)
  }

  public static removeItem(key: string) {
    AppStorage.load(key).remove()
  }
}
