import { useQuery } from '@tanstack/react-query'

import {
  CardListResponse,
  CardSchema,
  DashboardSchema,
  DashboardsResponse,
} from '@/api/analytics/type'
import { apiClient } from '@/api/client'
import { useCurrentTenant } from '@/atoms/tenant.atom'
import { downloadBlob } from '@/shared/utils/download-blob.utils'

export const exportAnalytics = async () => {
  const response = await apiClient.get<Blob>('/analytics/export', {
    responseType: 'blob',
  })
  downloadBlob(response.data, 'analytics.pdf')
}

const getCards = async (dashboardId: string) => {
  const response = await apiClient.get(
    `/analytics/dashboards/${dashboardId}/cards`
  )
  return CardListResponse.parse(response.data)
}

export const useCards = (dashboardId: string) =>
  useQuery({
    queryKey: ['cards', { dashboardId }],
    queryFn: () => getCards(dashboardId),
  })

const getCard = async (url: string, id: string) => {
  const response = await apiClient.get(
    `/analytics/dashboards/${url}/cards/${id}`
  )
  return CardSchema.parse(response.data)
}

export const useCard = (dashboard: string, id: string) =>
  useQuery({
    queryKey: ['card', { dashboard, id }],
    queryFn: () => getCard(dashboard, id),
  })

const getDashboards = async () => {
  const response = await apiClient.get('/analytics/dashboards')
  return DashboardsResponse.parse(response.data)
}

export const useDashboards = (enabled: boolean) => {
  const tenant = useCurrentTenant()
  return useQuery({
    queryKey: ['dashboards'],
    queryFn: getDashboards,
    enabled: Boolean(tenant) && enabled,
  })
}

export const getDashboard = async (slug: string) => {
  const response = await apiClient.get(`/analytics/dashboards/${slug}`)
  return DashboardSchema.parse(response.data)
}
