import type { PageModel } from '@primeit/react-core'

import { Config } from '@react-awesome-query-builder/mui'
import { useMutation, useQuery } from '@tanstack/react-query'

import {
  ReportRequestType,
  ReportSchema,
  ReportType,
  ReportsListResponse,
} from '@/api/reports/types'

import { apiClient } from '../client'

const getReports = async (params: PageModel | null) => {
  const response = await apiClient.get('/reports', { params })
  return ReportsListResponse.parse(response.data)
}

export const useReports = (params: PageModel | null) =>
  useQuery({
    queryKey: ['reports', params],
    queryFn: () => getReports(params),
  })

export const getReportConfig = async () => {
  const response = await apiClient.get<Config>('/reports/config')
  return response.data
}

export const getReportById = async (id: string) => {
  const response = await apiClient.get(`/reports/${id}`)
  return ReportSchema.parse(response.data)
}

const createReport = async (data: unknown) => {
  const response = await apiClient.post<ReportType>('/reports', data)
  return response.data
}

const updateReport = async (id: string, data: unknown) => {
  const response = await apiClient.put<ReportType>(`/reports/${id}`, data)
  return response.data
}

export const useMutateReport = (id?: string) =>
  useMutation({
    mutationKey: ['report', { id }],
    mutationFn: (data: ReportRequestType) => {
      const mappedData = {
        ...data,
        select: data.select?.map((d) => d.value),
        group: data.group?.map((d) => d.value),
        order: data.order?.map((d) => d.value),
      }
      if (id) return updateReport(id, mappedData)
      return createReport(mappedData)
    },
  })
