import { z } from 'zod'

export const IndexObjectSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  _extra: z.record(z.string(), z.any()).optional(),
})

export type IndexObject = z.infer<typeof IndexObjectSchema>

export const idExtractor = (obj: IndexObject) => obj.id
