import { z } from 'zod'

export const SimpleReportSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
})

export const ReportsListResponse = z.object({
  items: z.array(SimpleReportSchema),
  totalCount: z.number(),
  pageCount: z.number(),
  hasNext: z.boolean(),
})

export const ReportSchema = z.object({
  id: z.string(),
  type: z.string(),
  name: z.string(),
  where: z.string(),
  select: z
    .string()
    .array()
    .transform((arg) => arg.map((a) => ({ value: a }))),
  group: z
    .string()
    .array()
    .transform((arg) => arg.map((a) => ({ value: a }))),
  order: z
    .string()
    .array()
    .transform((arg) => arg.map((a) => ({ value: a }))),
})

export type ReportType = z.infer<typeof ReportSchema>

export const ReportRequestSchema = ReportSchema.partial({
  id: true,
})

export type ReportRequestType = z.infer<typeof ReportRequestSchema>
