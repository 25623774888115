import { z } from 'zod'

import i18n from '../../i18n/index'

export const converter = (str: Date) => str?.toISOString()

export const dateToStringTransformer = z.date().transform(converter)

export const stringToNumberTransformer = z.coerce.number({
  invalid_type_error: i18n.t('form_error_message.currency'),
})

export const nullableStringTransformer = (str?: string | null) =>
  !str ? null : str
