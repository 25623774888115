export type ColaboratorsSortingAndFilteringOptions =
  | 'sageNumber'
  | 'fullName'
  | 'birthDate'
  | 'numChildrenAndAges'
  | 'nationality'
  | 'personalPhone'
  | 'workPhone'
  | 'personalEmail'
  | 'workEmail'
  | 'address'
  | 'country'
  | 'office'
  | 'contract'
  | 'contractStartDate'
  | 'endOfTrialPeriodDate'
  | 'client'
  | 'function'
  | 'manager'
  | 'coef2'
  | 'billing'
  | 'value'
  | 'department'
  | 'status'
  | 'contractEndDate'
  | 'notes'

export enum ConvertedSortDirectionEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface ApiSortingParams {
  page: number
  perPage: number
  sortField: ColaboratorsSortingAndFilteringOptions
  sortDirection: ConvertedSortDirectionEnum
}
