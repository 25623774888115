import { primeApi } from '@primeit/react-auth'

import { isCancelledError } from '@tanstack/react-query'
import { AxiosError, isAxiosError, isCancel } from 'axios'

import { getLocalTenant } from '@/api/tenants/service'

const options = {
  apiUrl: import.meta.env.VITE_API_URL,
  ssoUrl: import.meta.env.VITE_SSO_URL,
  clientId: import.meta.env.VITE_SSO_CLIENT_ID,
}

const instances = primeApi(options)

export const apiClient = instances.apiClient

apiClient.interceptors.request.use((config) => {
  const tenant = getLocalTenant()
  if (tenant) {
    config.headers.set('X-Tenant', tenant.id)
  }
  return config
})

export const isRequestError = (error: unknown) => {
  if (isAxiosError(error)) return error as AxiosError
  return null
}

export { isAxiosError, isCancel, isCancelledError }

export type { AxiosError }
