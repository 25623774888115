import { LanguageInterface } from '@/i18n/labels/type'

export const pt: LanguageInterface = {
  error_pages: {
    title: 'Erro {{status}}',
    status: {
      404: 'A página que está procurando não existe.',
      500: 'Ocorreu um erro inesperado. Por favor, tente novamente!',
    },
  },
  translation: {
    follow_up: {
      title: 'Observações de {{name}}',
      new_follow_up: 'Nova Observação',
      nothing_to_show: 'Nenhuma informação a exibir',
      msgs: {
        SYS_COLLABORATOR_INACTIVATED: 'Colaborador inativado.',
        SYS_COLLABORATOR_ACTIVATED: 'Colaborador ativado',
      },
      system: 'Sistema',
    },
    messages: {
      employee_inactivated: 'A inativação do colaborador foi agendada.',
      form_contains_errors: 'Por favor, corrija os erros do formulário.',
      updating_leaving_schedule:
        'O colaborador já está com a inativação agendada. As alterações feitas aqui atualizarão o agendamento.',
      no_content: 'Nenhuma informação a exibir.',
      employee_saved: 'Os dados do colaborador foram salvos!',
      confirm_exit_lose_data: 'As informações fornecidas serão perdidas.',
    },
    pages: {
      analytics: 'Análises',
      dashboard: 'Painel',
      settings: 'Configurações',
      career_plan: 'Plano de carreira',
      career_plan_assign: 'Atribuição de níveis',
      profiles: 'Gerenciar Perfis',
      career_plan_management: 'Gestão',
      reports: 'Relatórios',
      manage_reports: 'Gerenciar relatórios',
      goals: 'Metas',
    },
    enums: {
      gender: {
        MALE: 'Homem',
        FEMALE: 'Mulher',
      },
      billing: {
        DAILY: 'Diário',
        MONTHLY: 'Mensal',
      },
      status: {
        ACTIVE: 'Activo',
        ONBOARDING: 'Entrada pendente',
        OFF_BOARDING: 'Saída pendente',
        INACTIVE: 'Inactivo',
      },
      language: {
        EN: 'Inglês',
        PT: 'Português',
      },
    },
    labels: {
      ok: 'OK',
      yes: 'Sim',
      no: 'Não',
      save: 'Salvar',
      cancel: 'Cancelar',
      timeline: 'Ver histórico',
      edit: 'Editar',
      filter: 'Filtro',
      search: 'Pesquisar',
      go_back: 'Voltar',
      confirm: 'Confirmar',
      action_forbidden: 'Não é possível realizar esta ação',
      create_employee: 'Criar colaborador',
      noRows: 'Nenhum resultado',
      rowsSelected_one: '1 linha selecionada',
      rowsSelected_other: '{{count}} linhas selecionadas',
      type_to_search: 'Digite para pesquisar...',
      loading: 'Carregando...',
      updated: 'Atualizado em',
      export: 'Exportar',
      apply: 'Aplicar',
      sortIconLabel: 'Ordenar',
      dataFormat: "d 'de' LLLL '-' HH'h'mm",
      dataFormatNoHours: "dd'/'MM'/'yyyy",
      copied: 'Copiado!',
      no_results_found: 'Nenhum resultado encontrado!',
      no_items_exist: 'Nenhum item para exibir!',
      form_contains_errors:
        'Existem campos inválidos. Por favor, verifique e tente novamente!',
      warning: 'Atenção',
      select_all: 'Selecionar tudo',
      rows_selected: 'registos selecionados',
      row_selected: 'registo selecionado',
      clear_selection: 'Limpar seleção',
    },
    topbar: {
      logout: 'Sair',
    },
    categories: {
      personal: 'Informações pessoais',
      contract: 'Informações de contrato',
      company: 'Informações da empresa',
    },
    form_tabs: {
      contract_info: 'Contrato',
      company_info: 'Info Prime',
      player_info: 'Info Player',
    },
    form_error_message: {
      personal_email: 'Insira um e-mail pessoal válido. Ex: xxxx@gmail.com',
      prime_email: 'Insira um e-mail prime válido. Ex: xxxx@primeit.pt',
      currency: 'Insira apenas valores númericos no contrato.',
      name: 'Nome deve conter ao menos 1 caractere.',
      zip: 'Insira um código postal válido. Apenas números e "-".',
      personal_phone:
        'Insira um telefone pessoal válido com código de país. Ex: +55...',
      prime_phone:
        'Insira um telefone prime válido com código de país. Ex: +55...',
    },
    global_table: {
      labels: {
        clear_filters: 'Apagar Filtros',
        clear_all_filters: 'Apagar todos os filtros',
        open_quick_view: 'Abrir Visualização Rápida',
        open_new_tab: 'Abrir Novo Separador',
        edit: 'Editar',
        inactivate: 'Inativar',
        no_filters_applied: 'Não aplicou nenhum filtro.',
        results_found_other: '{{count}} resultados encontrados',
        results_found_one: '1 resultado encontrado',
        results_found_zero: 'Nenhum resultado encontrado',
      },
      columns: {
        paidLeave: 'Férias pagas',
        nationality: 'Nacionalidade',
        client: 'Cliente',
        department: 'Departamento',
        sage_number: 'Sage',
        name: 'Nome Completo',
        personal_email: 'Email Pessoal',
        personal_phone: 'Telemóvel Pessoal',
        birth_date: 'Data de Nascimento',
        age: 'Idade',
        gender: 'Género',
        dependents: 'Dependentes',
        dependents_age: 'Idade',
        dependents_birth_date: 'Data de nascimento',
        dependents_name: 'Nome',
        country: 'País',
        region: 'Localidade',
        residence: 'Morada',
        postal_code: 'Código postal',
        language: 'Idioma Padrão',
        role: 'Função',
        prime_email: 'Email Prime',
        prime_phone: 'Telemóvel Prime',
        businessManager: 'Business Manager',
        businessUnitManager: 'Business Unit Manager',
        people_officer: 'HR Business Partner',
        team: 'Equipa Prime',
        office: 'Escritório',
        managementOffice: 'Escritório de faturamento',
        project: 'Projeto',
        contract: 'Contrato',
        iefp: 'IEFP',
        contract_started_at: 'Início Contrato',
        end_contract_experimental_period: 'Fim Período Experimental',
        end_contract: 'Término do Contrato',
        leaving_reason: 'Motivo de Saída',
        rate: 'Rate',
        billing: 'Faturação',
        coef2: 'COEF2',
        status: 'Status',
        salary_component: 'Componente Salarial',
        salary_component_base_value: 'Valor base',
        salary_component_child_ticket: 'Ticket Infância',
        salary_component_iht: 'IHT',
        salary_component_ac: 'AC',
        salary_component_foodTicket: 'Cartão refeição',
        salary_component_carTicket: 'Viatura',
        salary_component_una: 'UNA',
        salary_component_rate: 'Valor Total',
        observations: 'Observações',
        skill: 'Skill',
        project_start_date: 'Início do projeto',
        invoice_start_date: 'Inicio da faturação',
        financialStatus: 'Status financeiro',
        work_contact: 'Contactos de trabalho',
        personal_info: 'Informações pessoais',
        contact: 'Contactos',
        contractEndDate: 'Fim do contrato',
        follow_up: 'Observações',
        tenant: 'Empresa',
        externalId: '#{{name}}',
      },
      export_files: {
        all_selected: 'Tudo selecionado',
        some_selected_one:
          '{{count}} de {{totalFilesNumber}} registos selecionados',
        some_selected_other:
          '{{count}} de {{totalFilesNumber}} registos selecionados',
        specific_selected_one: '{{count}} selecionado',
        specific_selected_other: '{{count}} selecionados',
        export: 'Exportar',
        excel: 'Excel',
        csv: 'CSV',
        select_all_pages: 'Selecionar todas as páginas',
        all_pages_selected: 'Todas as páginas selecionadas',
      },
      row_count: {
        all_selected: 'Tudo selecionado',
        some_selected_one: '{{count}} selecionado',
        some_selected_other: '{{count}} selecionados',
        select_all_action: 'Selecionar todos os colaboradores',
        all_selected_undo: 'Desfazer',
      },
    },
    inactive_modal: {
      title: 'Inativar Colaborador',
      modal_description:
        'Está a agendar a inactivação de <highlight>{{employeeName}}</highlight>. Pra continuar preencha os campos a seguir:',
      schedule_inactivation_button: 'Agendar inactivação',
      cancel_button: 'Cancelar',
    },
    analytics: {
      table: {
        employees_by_department: 'Pessoas por departamento',
        status: 'Estado',
        goal: 'Meta {{year}}',
        consultants: 'Consultores',
      },
      lines: {
        total_consultants_invoiced_lisbon:
          'TOTAL CONSULTORES FACTURADOS LISBOA',
        total_consultants_invoiced_porto: 'TOTAL CONSULTORES FACTURADOS PORTO',
        total_ic: 'TOTAL IC',
        total_prime_solution_interco: 'TOTAL PRIME SOLUTIONS',
        total_unpaid_leave: 'TOTAL DE BAIXAS',
        total_not_invoiced_in_project: 'TOTAL NÃO FATURADO NO PROJETO',
        total_not_invoiced_maternity_paternity_leave:
          'TOTAL DE LICENÇA DE MATERNIDADE NÃO FATURADA',
        total_not_invoiced_sick_leave: 'TOTAL DE LICENÇA MÉDICA NÃO FATURADA',
        total_consultants: 'TOTAL CONSULTORES',
        total_effectives: 'TOTAL EFETIVOS',
        total_company: 'TOTAL COMPANHIA',
        effectives: 'EFETIVOS',
        freelance: 'FREELANCE',
        in_project: 'EM PROJETO',
        trainees: 'ESTAGIÁRIOS',
        goal_invoiced_lisbon: 'FACTURADO LISBOA',
        goal_difference_lisbon: 'DIFERENÇA LISBOA',
        goal_invoiced_porto: 'FACTURADO PORTO',
        goal_difference_porto: 'DIFERENÇA PORTO',
        goal_invoiced_total: 'FACTURADO TOTAL',
        goal_difference: 'DIFERENÇA',
      },
    },
  },
  profile_management_page: {
    columns: {
      email: 'E-mail',
      tagLine: 'Função',
      fullName: 'Nome',
      moduleRole: 'Permissão',
    },
    labels: {
      edit: 'Editar',
      add_permission: 'Adicionar',
      search: 'Pesquisar',
      submit_change: 'Pronto',
      selected_items: 'Itens selecionados',
      clear_filter: 'Limpar filtro',
      reset_profile: {
        title: 'Alterar acesso',
        message:
          'Tem certeza de que deseja remover o perfil de <highlight>{{role}}</highlight> de <highlight>{{name}}</highlight>?',
        confirm: 'Sim',
        cancel: 'Cancelar',
      },
      no_selected_item: 'Nenhum item selecionado!',
      apply_button: 'Aplicar',
      change_role: {
        title: 'Alterar acesso',
        description:
          'Tem certeza de que deseja alterar o acesso de <highlight>{{name}}</highlight> de <highlight>{{oldRole}}</highlight> para <highlight>{{currRole}}</highlight>?',
        confirm: 'Confirm',
        cancel: 'Cancel',
      },
      add_profile_title: 'Adicionar {{name}}',
      reset_button: 'Resetar acesso',
      filter_button_label: 'Filtros',
    },
  },
  career_plan: {
    labels: {
      add: 'Plano de Carreira',
      edit: 'Editar',
      title: 'Gestão de Plano de Carreira',
      clear_filters: 'Limpar filtros',
      clear_filter: 'Limpar filtro',
      search: 'Pesquisar',
      search_placeholder: 'Digite para pesquisar...',
      selected_items: 'Opções selecionadas',
      done: 'Feito',
      filters: 'Filtros',
      no_item_selected: 'Nenhuma opção selecionada.',
      no_filters: 'Nenhum filtro selecionado.',
      save: 'Salvar',
      cancel: 'Cancelar',
      level_change: 'Mudança de nível',
      change_level: 'Mudar nível',
    },
    columns: {
      name: 'Nivel',
      baseSalary: 'Salário Base',
      career_level: 'Nível',
      department: 'Departamento',
      role: 'Cargo',
    },
    messages: {
      form_field_errors: 'Por favor, verifique os seguintes campos: {{fields}}',
      career_plan_added: 'O plano de carreira foi criado com sucesso!',
      error_saving_career_plan:
        'Não foi possível salvar o plano de carreira. Por favor, tente novamente!',
      career_plan_saved: 'O plano de carreira foi salvo com sucesso!',
      one_level_required:
        'O plano de carreira precisa ter pelo menos um nível.',
      confirm_change_level_zero:
        'Deseja alterar o nivel de <highlight>{{nome}}</highlight> para <highlight>{{newRole}}</highlight>?',
      confirm_change_level_one:
        'Deseja alterar o nivel de <highlight>{{nome}}</highlight> de <highlight>{{oldRole}}</highlight> para <highlight>{{newRole}}</highlight>?',
      level_changed: 'O nível de carreira foi atualizado.',
      career_plan_removed: 'O plano de carreira foi removido!',
      career_plan_removal_error:
        'O plano de carreira não pôde ser removido. Por favor, tente novamente!',
      confirm_career_plan_removal:
        'Tem a certeza de que deseja remover o plano de carreira? Esta alteração não poderá ser desfeita.',
      cannot_delete_plan:
        'Existem colaboradores vinculado a um ou mais níveis do plano de carreira.<br/>É necessário associar outros níveis aos colaboradores na janela <link/>.',
      cannot_delete_level:
        'Existem colaboradores vinculados ao nível selecionado.<br/>É necessário associar outros níveis aos colaboradores na janela <link/>.',
    },
  },
  employee_history: {
    salary_zero: 'Vencimento removido',
    salary_one: 'Alteração de vencimentos para £<b>{{value}}</b>',
    role_end_zero: 'Função removida',
    role_end_one: 'Saída da função de <b>{{name}}</b>',
    role_start_zero: 'Função removida',
    role_start_one: 'Promoção a <b>{{name}}</b>',
    contract_start_zero: 'Contrato encerrado',
    contract_start_one: 'Início do contrato <b>{{name}}</b>',
    contract_end_zero: 'Contrato removido',
    contract_end_one: 'Alteração do contrato para <b>{{name}}</b>',
    career_level_end_zero: 'Progressão de carreira removida',
    career_level_end_one: 'Fim da progressão de carreira em <b>{{name}}</b>',
    career_level_start_zero: 'Progressão de carreira removida',
    career_level_start_one: 'Promoção ao plano de carreira <b>{{name}}</b>',
  },
  goals_page: {
    title: 'Metas',
    current: 'Atualmente',
    expected: 'Esperado',
    of: 'de',
    total: 'Total',
    completed: 'Concluído',
    active: 'Ativa',
  },
  filters: {
    date_start: 'Desde',
    date_to: 'Até',
    clear: 'Limpar',
  },
}
