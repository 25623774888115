import { useTranslation } from 'react-i18next'

import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import {
  Employee,
  EmployeeEditResponseSchema,
  EmployeeHistoryResponseType,
  EmployeeRequest,
  EmployeesResponse,
  FollowUpListSchema,
  IPagination,
  InactivateRequestType,
} from '@/api'
import { apiClient } from '@/api/client'
import { ErrorResponseDto } from '@/api/error-response.dto'
import { useCurrentTenant } from '@/atoms/tenant.atom'
import { OnExportData } from '@/components/ExportFiles'
import { useNotification } from '@/hooks/useNotification'
import { queryClient } from '@/query-client'
import { downloadBlob } from '@/shared/utils/download-blob.utils'

const getEmployees = async (
  pagination: IPagination,
  filters: Filters
): Promise<Employee[]> => {
  const params = {
    ...filters,
    ...pagination,
    search: filters.search ?? undefined,
  }
  const { data } = await apiClient.post<Employee[]>(`/employees`, params)
  return EmployeesResponse.parse(data)
}

export const useEmployees = (filters: Filters, pagination: IPagination) => {
  const tenant = useCurrentTenant()
  return useQuery({
    queryKey: ['employees', { pagination, filters }],
    queryFn: () => getEmployees(pagination, filters),
    enabled: Boolean(tenant),
  })
}

export const getEmployee = async (id: string) => {
  const request = await apiClient.get(`/employees/${id}`)
  return EmployeeEditResponseSchema.parse(request.data)
}

export interface UseEmployeeParams {
  enabled?: boolean
}

interface Filters {
  search?: string
  nationalityIds?: string[]
  clientIds?: string[]
  departmentIds?: string[]
  roleIds?: string[]
  countryIds?: string[]
  officeIds?: string[]
  managementOfficeIds?: string[]
  teamIds?: string[]
  contractIds?: string[]
  businessManagerIds?: string[]
  businessUnitManagerIds?: string[]
  peopleOfficerIds?: string[]
  genders?: string[]
  status?: string[]
  financialStatusIds?: string[]
  leavingReasonIds?: string[]
  billing?: string[]
  language?: string[]
  tenantIds?: string[]
  iefp?: boolean
  contractEndDate?: {
    startDate: Date | null
    endDate: Date | null
  } | null
}

type ExportUsers = Filters & OnExportData

const exportUsersByFileType = async (params: ExportUsers) => {
  const { data } = await apiClient.post<Blob>('/employees/export', params, {
    responseType: 'blob',
  })

  return downloadBlob(data, `employees.${params.type}`)
}

export const useExportUsers = () =>
  useMutation({
    mutationKey: ['export_users'],
    mutationFn: exportUsersByFileType,
  })

const handleInactive = async ({
  employeeId,
  ...data
}: InactivateRequestType) => {
  return await apiClient.put<Employee>(
    `/employees/${employeeId}/inactivate`,
    data
  )
}

export const useInactivate = () =>
  useMutation({
    mutationFn: handleInactive,
  })

const createEmployee = async (data: EmployeeRequest) => {
  const response = await apiClient.post<Employee>('/employees/create', data)
  return response.data
}

const updateEmployee = async ({ id, ...data }: EmployeeRequest) => {
  const response = await apiClient.put<void>(`/employees/${id}`, data)
  return response.data
}

export const useMutateEmployee = () => {
  const notification = useNotification()
  const { t } = useTranslation()

  return useMutation({
    mutationKey: ['employee'],
    mutationFn: async (data: EmployeeRequest) => {
      if (data.id) return await updateEmployee(data)
      else return await createEmployee(data)
    },
    onSuccess: () => {
      notification.addNotification({
        type: 'success',
        message: t('messages.employee_saved'),
      })
      void queryClient.invalidateQueries({
        queryKey: ['employees'],
        exact: false,
      })
      void queryClient.invalidateQueries({
        queryKey: ['employee'],
        exact: false,
      })
    },
    onError: (error: Error | AxiosError) => {
      if (error instanceof AxiosError) {
        const data = (error as AxiosError<ErrorResponseDto>).response?.data
        if (data?.message)
          if (Array.isArray(data.message))
            for (const message of data.message) {
              notification.addNotification({
                type: 'error',
                message,
              })
            }
          else {
            notification.addNotification({
              type: 'error',
              message: data.message,
            })
          }
      }
    },
  })
}

const getHistory = async (employeeId: string) => {
  const response = await apiClient.get<EmployeeHistoryResponseType>(
    `/employees/${employeeId}/timeline`
  )
  return response.data
}

export const useEmployeeHistory = (employeeId: string) => {
  return useQuery({
    queryKey: ['employee_history', employeeId],
    queryFn: () => getHistory(employeeId),
  })
}

const getFollowUps = async (id: string) => {
  const response = await apiClient.get(`/employees/${id}/follow-ups`)
  return FollowUpListSchema.parse(response.data)
}

export const useFollowUps = (id: string) => {
  return useQuery({
    queryKey: ['follow-up', id],
    queryFn: () => getFollowUps(id),
  })
}

const addFollowUp = async ({
  id,
  ...data
}: {
  id: string
  content: string
}) => {
  await apiClient.post(`/employees/${id}/follow-ups`, data)
}

export const useAddFollowUp = () =>
  useMutation({
    mutationKey: ['follow-up'],
    mutationFn: addFollowUp,
  })
