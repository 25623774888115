import { z } from 'zod'

import { ConvertedSortDirectionEnum } from '@/api'

export const PaginationSchema = z.object({
  page: z.number().min(1),
  perPage: z.number().min(1),
  sortField: z.string().optional(),
  sortDirection: z.nativeEnum(ConvertedSortDirectionEnum).optional(),
})
